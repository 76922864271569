import React from "react";

export default ({namespace, schema, thing, action}) => {
  const targetHRef = React.useMemo(() => {
    const url = new URL(action.url);
    const params = new URLSearchParams(url.search);
    params.append("n", namespace.domain);
    params.append("s", schema.id);
    params.append("t", thing.id);
    url.search = params;
    return url.toString();
  }, [namespace, schema, thing, action]);
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      className={`btn btn-block btn-lg ${action.class}`}
      role="button"
      href={targetHRef}
    >
      {action.label}
    </a>
  );
};
